//
// Environment
//

//
// Config values
//
$platform-name: "neuroblastomaaustralia";
$assets-path: "../assets/";

//
// Colours
//
$brand-primary: #d61a69;
$brand-secondary: #5fbde4;
$grey: #58595b;
$donate-colour: $brand-primary;
$yellow: #fbd12d;
$bg_grey: #ece9e9;
// Create greyscale

// Utility colours

//
// Layout
//

// Breakpoints

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
//
// Misc
//
$body-colour: $grey;
//
// Transitions
//

//
// Logo
//
$logo: "logo.jpg";
$logo-width: 325px;
$logo-height: 130px;
$logo-absolute: true;

$logo-breakpoint: 768px;
$logo-mobile: "logo_mobile-1.png";
$logo-mobile-width: 125px;
$logo-mobile-height: 105px;

//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Neue Helvetica W05", sans-serif;
$headings-colour: #58595b;
$font-weight-bold: 400; // This was previously set to 100 (?) - which is causing some bugs: https://www.bugherd.com/projects/174515/tasks/39
// Links

// Icon font

//
// Buttons
//
$border-radius: 10px;
$btn-border-radius: $border-radius;
$btn-padding-x: 2em;
$btn-padding-y: 1em;
$btn-font-family: $font-open-sans;
$btn-font-weight: 600;
$btn-background-colour: $brand-secondary;
$btn-text-colour: white;
// Donate button
$donate-btn-colour: white;
//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 25px;
$social-icons-height: 25px;
$social-icons-font-size: 0.7rem;

// $social-icons-background-colour: #989898;
$social-icons-colour: #fff;
// $social-icons-background-colour: "brand";
$social-icons-background-colour: $brand-secondary;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//

// Header content
$header-content-margin-bottom: 1rem;
$header-content-padding-top: 0px;
$header-content-padding-bottom: 0px;
$header-content-margin-top: 1rem;
// Tagline
$tagline-enabled: true;
$tagline-font-size: 14px;
$tagline-colour: #a0a0a0;
$tagline-breakpoint: 900px;

// Main call to action
$cta-btn-gap: 20px;
$cta-btn-background-colour: $brand-secondary;
// Search
$header-search-margin-right: 5px;

// Search - input
$header-search-input-max-width: 120px;
// $header-search-input-max-width: 0;
// $header-search-input-margin-right: 0;
// $header-search-input-padding: 0px 0px;
// $header-search-input-border-colour: #fff;
// $header-search-input-margin-right: 0px;

// Search - button
// $header-search-button-background-colour: #989898;
$header-search-button-background-colour: $brand-secondary;
$header-search-button-icon-colour: #fff;
// $header-search-button-border-radius: 100px;
$header-search-button-border-radius: 10px;
$header-search-button-width: 25px;
$header-search-button-height: 25px;
// Search when in nav

// Social icons

//
// Sticky header
//

//
// Navigation
//
$nav-type: normal;
$nav-background-colour: $brand-primary;
$nav-normal-align-items: right;
$nav-top-level-item-colour: #000;
$nav-font-family: $font-open-sans;

// Top level items
$nav-top-level-item-padding: 15px 20px;
$nav-top-level-item-colour: white;
$nav-submenu-item-hover-background-colour: rgba($brand-secondary, 0.4);
// Submenus

// Burger button
$burger-btn-bar-breakpoint: 600px;


// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-border-radius: 0;
//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//

$carousel-max-width: 100%;
$carousel-read-more-enabled: true;
$carousel-summary-colour: white;
$carousel-heading-colour: white;
$carousel-details-background-colour: rgba($brand-primary, 0.8);
$carousel-details-padding: $spacer * 2;
$carousel-details-border-radius: $border-radius;
$carousel-details-position-y: center;
$carousel-border-radius: 0;
$carousel-details-margin-x: 40px;
$carousel-read-more-background-colour: $brand-secondary;
// Carousel controls (left-right buttons)

// Carousel buttons (dots or tabs)

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-font-size: 1rem;
$home-intro-padding-y: $spacer * 0.5;
$home-intro-margin-top: 2rem;
//
// Home features
//
$home-features-padding-top: $spacer;
$home-features-padding-bottom: $spacer * 2;
//
// Impact stats
//
$impact-stats-background-image: "impact2.png";
$impact-stats-background-image-opacity: 0.1;
$impact-stats-colour: white;
// Heading

// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
// Summary

//
// Home feeds
//
$home-feeds-background-colour: $bg_grey;
// Feeds title

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//
$footer-boxes-count: 3;
$footer-prefab: 4;
// Footer admin links

// Newsletter
$newsletter-button-colour: white;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//
$sidebar-mobile-layout: below;
// Associated lists

//
// Listing
//
$listing-cards-per-row-desktop: 3;
// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: false;

// Top fundraisers

//
// Donation form
//

// Donation amounts
$donation-amount-min-width: 250px;

//
// Quick giving panel
//

//
// Home quick giving panel
$home-quick-giving-content-max-width: 1230px;

// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

$blog-details-enabled: false;

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//

// Subsite logo

// Subsite nav

//
// Cookie consent
//
