//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab($number-of-cards: 3, $prefab: 5);
}

// Choose your card style for home feature cards
.homeBox1,
.homeBox2 {
  @include card;
  @include card-basic;
}

.homeBox3 {
  @include card(
    $card-details-background-colour: $brand-primary,
    $card-details-padding: 6rem 4rem 160px,
    $card-heading-colour: white,
    $card-summary-colour: white
  );
  @include card-side-by-side(
    $card-side-by-side-image-width: 70%,
    $card-side-by-side-breakpoint: 768px
  );

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
      padding: 1rem;
    }
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.
// .homeBox1{
//   @include card;
//   @include card-side-by-side;
// }

//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab($number-of-feeds: 2, $prefab: 1);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab($number-of-cards: 2, $prefab: 1);
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab($number-of-cards: 2, $prefab: 1);
}

// Choose your card style for home feed cards
.homeFeedBox1 .feedItem {
  @include card;
  @include card-basic;
}
  
.homeFeedBox2 .feedItem {
  @include card;
  @include card-side-by-side;
}
  

//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card($card-hover-details-background-colour: $brand-secondary);
  @include card-basic;
}

//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}
