// Header - mobile
@media (max-width: 768px) {
  a.mainLogo { position: relative; }
}
@media (max-width: 600px) {
  a.menuMainAlt { margin: 0 !important; }
  .pageHeader .headerContent {
    padding-bottom: 55px !important;
    margin-bottom: 0 !important;
  }
}


//navigation
.menuMain {
  margin-bottom: 0rem;
}

//intro
.homeIntro h3 {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 5px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

//carousel
.carouselSlideHeading {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 5px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

//homeBox3
.homeBox3 .homeFeatureDetailsWrapper {
  // background-image: url(../assets/icon.svg);
  background-image: url(../assets/drawn_icon.png);

  background-size: 160px;
  background-repeat: no-repeat;
  background-position-y: 98%;
  background-position-x: 98%;

  @media (max-width: 768px) {
    background-image: none;
  }
}
.homeBox3 h2 {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 5px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}
.homeBox3 {
  margin: $spacer 15px $spacer;
}

// home feeds - underlines
.homeFeed .feedsTitle {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 5px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 2rem
  );
}

// home feed
.homeFeedBox2 .feedItem {
  margin: 0 15px 30px;
}

//impact section
.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 5px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 2rem
  );
}
.homefeaturecategory-homeboximpactstats .homeImpactWrapper img {
  max-width: initial;
  width: 75%;
}
.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
  margin-bottom: 0rem;
}

// Stats styling
.homeImpactWrapper {
  .fa {
    font-size: 4em;
    margin-bottom: $spacer * 1;
    color: rgba($brand-secondary, 1);
  }

  table tbody {
    align-items: flex-start;
  }

  td:nth-child(1) {
    order: 2;
  }

  td:nth-child(2) {
    order: 3;
  }

  td:nth-child(3) {
    order: 1;
  }

  table td h2 {
    font-size: 4em;
    line-height: 1.1;
    // Fix for impact stat figure
    color: white;
  }
}

//quick giving panel
.homefeaturecategory-homeboxquickgiving
  .quickGivingPanel
  .formQuestion.donationAmount
  .donationAmountFigure {
  color: #fff;
}

//footer
.pageFooter h1 {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 5px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}
ul.footerLinks {
  text-align: center;

  li {
    display: inline-block;
    padding: 0 5px;
  }
}
.footerBox3 {
  margin-top: 0;
  text-align: center;
  font-size: 14px;
  p {
    padding: 10px 0 0;
  }
}

// footer - social icons
.pageFooter ul.socialIcons {
  margin: 20px auto 20px;
  display: table;
  li {
    display: inline-block;
  }
}
.pageFooter .socialIcons a:before {
  font-size: 1rem;
}

//footer logos
.footerBox2 {
  text-align: center;
  h4 {
    @include heading-underline(
      $colour: $yellow,
      $width: 50px,
      $height: 5px,
      $justify: center,
      $margin-top: 1rem,
      $margin-bottom: 1rem
    );
    text-align: center;
  }

  p {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  img {
    width: auto !important;
    height: auto !important;
    max-height: 115px;
    max-width: 140px;
    display: inline-block;
    padding: 10px;
    margin: 0px 10px 10px;
  }
}

// donate btn
.cta-button.donate {
  padding: 1em 1.8em 1em 2em;
  &::after {
    content: "\f004";
    font-family: "FontAwesome", serif;
    color: white;
    margin-left: 10px;
  }
  // Mobile header layout
  @media (max-width: map-get($breakpoints, sm)) {
    min-width: 130px;
  }
}

// shop btn
.cta-button.shop {
  padding: 1em 1.8em 1em 2em;
  &::after {
    content: "\f07a";
    font-family: "FontAwesome", serif;
    color: white;
    margin-left: 10px;
    // Mobile header layout
    @media (max-width: map-get($breakpoints, sm)) {
      display: none;
    }
  }
}

// newsletter btn
.cta-button.newsletter {
  padding: 1em 1.8em 1em 2em;
  &::after {
    content: "\f0e0";
    font-family: "FontAwesome", serif;
    color: white;
    margin-left: 10px;
  }
}

//donate buttons
.cta-button.donate,
.button.donate,
button.donate {
  &::after {
    content: "\f004";
    font-family: "FontAwesome", serif;
    color: white;
    margin-left: 10px;
  }
}

//load more button
a.button.loadMore {
  background-color: $brand-secondary;
}

//listing page underline
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem)
  h2 {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 5px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

//inner page h1 underlines
.postContent h1,
.postContent h2 {
  @include heading-underline(
    $colour: $yellow,
    $width: 50px,
    $height: 5px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

// header small screens
.mainCallToAction p {
  margin: 0;
  text-align: right;
}
.mainCallToActionButtons a.cta-button,
a.menuMainAlt {
  margin: 2px 0 2px 5px;
}

span.tagline {
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  margin-right: 5px;
  a {
    color: $brand-secondary;
  }
  img {
    vertical-align: sub;
  }
  @media (max-width: 859px) {
    display: none;
  }
}

// header desktop
@media (min-width: 1025px) {
  .headerContent .mainCallToAction {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    max-width: 350px;

    form.header-search {
      position: relative;
      display: inline-flex;
      order: 1;
    }

    .socialIcons {
      order: 2;
      position: relative;
      display: inline-flex;
      margin: 0 0 0 5px;
    }

    .mainCallToActionButtons {
      order: 3;
      width: 100%;
      a.cta-button {
        margin: 20px 0 0 10px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  // header search
  .headerContent .header-search #siteSearch {
    max-width: 140px;
    font-size: 14px;
    border-color: #fbd12d;
    margin-right: -30px;
    padding-right: 30px;
  }

  // header tagline
  span.tagline {
    margin: 15px 0 auto 0 !important;
    align-items: flex-start;
  }
}

//homeBox4 video homepage

.homeBox4 > * {
  width: 50%;
}

.homeBox4 {
  max-width: calc(1230px + 30px);
  width: 100%;
  padding: 5rem $site-bleed;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  .text, .video  {
    width: 100%;
    flex: 1;
    margin: 0 10px;
  }
  .text {
    p {
      font-weight: 100;
    }
    h3 {
      padding-top: 30px;
      @include heading-underline(
        $colour: $brand-secondary,
        $width: 50px,
        $height: 5px,
        $justify: left,
        $margin-top: 1rem,
        $margin-bottom: 1rem
      );
    }
  }
  .video iframe {
    width: 100%;
    border-radius: $border-radius;
  }

  @media (max-width: map-get($breakpoints, md)) { 
    flex-direction: column;
    .text, .video {
      margin: 10px 0;
    }
  }
}

@media (max-width: map-get($breakpoints, md)) {
  .homeBox4 {
    margin: 0 auto 0px;
  }
}

// Designer is moving the header-search, which is causing some framework cloning issues
// The search is appearing in the nav twice, so I'm just hiding the second one we have
.menuMain .header-search + .header-search {
  display: none;
}

// DES-3481 Quick fix for nav menu alignment, pending https://trello.com/c/6v76kon9/204-logic-for-nav-submenu-position-doesnt-always-make-sense
@media (min-width: 1025px) {
  ul.topLevel > li:nth-last-of-type(4) {
    ul.subMenu:not(.level2) {
      left: 99%;
    }
  }
}

// https://trello.com/c/WeOC26No/4
// Make specific listing pages 3 columns wide rather than 4
@media (min-width: map-get($breakpoints, lg) + 1px) {
  .blogs-committee-Category-committee,
  .blogs-neuroblastoma-australia-team-Category-neuroblastoma-australia-team,
  .blogs-scientific-advisory-board-Category-scientific-advisory {
    .listedPost {
      width: calc((100% - #{$gap-width * 3}) / 3 - 1px);
    }
  }
}

// Custom homepage donate snippet
.donateSnippetWrapper {
  max-width: 1230px;
  margin: auto;
  text-align: center;
  .cta-donate {
    background-color: $brand-primary;
  }
  h3 {
    margin-bottom: 20px;
    @include heading-underline(
      $colour: $yellow,
      $width: 50px,
      $height: 5px,
      $justify: center,
      $margin-top: 1rem,
      $margin-bottom: 1rem
    );
  }
}

// Custom newsletter signup DES-3528
.footerBox[class*="ewsletter"] {
  div form {
    display: flex;
    flex-wrap: wrap;
    max-width: 1280px;
    margin: auto;
    padding: 0 $site-bleed;
  }
  p {
    flex: 1;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 0;
    min-width: 200px;
    button {
      bottom: -30px;
    }
    input {
      width: 100%;
    }

    &:last-of-type {
      flex: 0;
      min-width: auto;
      margin-right: 0;
    }
  }
}

@media (max-width: 1024px) {
  .footerBox[class*="ewsletter"] span p {
    width: 100%;
  }
}

.content.postContent.pageContent input[type="text"] {
  width: 50%;
}

// Set 'set up a regular donation' button to grey on mobile
// https://impactful.slack.com/archives/C45KGSY2U/p1583206331000100
@media (max-width: map-get($map: $breakpoints, $key: sm)) {
  .donationForm .yourDonationHeader a {
    background-color: $grey;

    &:hover {
      background-color: darken($grey, 10%);
    }
  }
}

// Breadcrumbs on blogs
.blogs, .blogsBody, .blogsPostBody {
  .breadcrumbWrapper {
    display: none;
  }
}

.give-a-gift-to-a-child-with-cancer .donationAmountOther {
  display: none;
}


// Donate form - Add radio button to donation amounts
.yourDonation .donationAmountsWrapper .formQuestion.donationAmount {
  .checkboxRadio {
    flex-direction: unset;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .donationAmountThumbnail,
  .donationAmountDescription {
    flex: unset;
    width: 100%;
  }
  input[type="radio"] {
    display: inline-flex;
    padding-left: 15px;
    margin-top: 15px;
  }
}

// Change request 324072



// Blue form 

@mixin blue-form {
  background-color: lighten($brand-secondary, 32%);
  border: solid $brand-secondary 5px;
}

.appealCreatePage {
  .postCreateForm {
    @include blue-form;
    padding: 20px;
    legend {
      background-color: transparent;
    }
    .insert-text {
      color: $grey;
    }
    fieldset:not(.formButtons) {
      border: 1px solid $grey;
    }
    .registerWrapper {
      padding: 30px 0 0;
    }
    label#insertTextSummary, label#insertTextContent {
      color: $grey;
      font-weight: bold;
    }
    .contactYou p span {
      background-color: transparent!important;
      font-weight: bold;
    }
    div#uploadImage {
      background-color: $brand-primary;
    }
  }
}

// Make fundraising Form 2/3 width of page 

.appealCreatePage {
  .postCreateFormWrapper {
    max-width: $container-max-width;
    margin: auto;
    padding: 0 20px;
  }
  .postCreateForm {
    @media (min-width: map-get($breakpoints, md)) {
    width: 66%;
    margin: auto;
    }
  }
}


// Add border box to individual fundraiser pages

.postContent.fundraiserContent {
  border: 1px solid #eceeef;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

// 998917 SA - Raisely donate page
// https://www.neuroblastoma.org.au/appeal/donate-today
// https://www.neuroblastoma.org.au/Appeal/donate-to-our-survive-and-thrive-campaign-this-eofy
// https://www.neuroblastoma.org.au/appeal/hazzys-christmas-wish
body.appeal-donate-today, 
body.appeal-donate-to-our-survive-and-thrive-campaign-this-eofy,
body.appeal-hazzys-christmas-wish {
  //.postAside,
  .breadcrumbWrapper,
  .contentBlockWrapper.appealActionsWrapper,
  .shareWrapper,
  .appealInteractionsWrapper {
    display: none;
  }
  .donationwidget {
    margin-top: 10px;
  }
  .contentBlockWrapper {
    margin-top: 0;
  }
  h1.title {
    font-size: 2.5em;
  }
}

@media (min-width: 750px) {
  body.appeal-donate-today .headerText{
    background-image: linear-gradient(to right, $brand-primary 30%, transparent 70%), url("https://www.neuroblastoma.org.au/Handlers/GetImage.ashx?IDMF=cc405ae1-2b42-4050-9821-b6d1cb887690");
  }
  body.appeal-donate-to-our-survive-and-thrive-campaign-this-eofy .headerText{
    background-image: linear-gradient(to right, $brand-primary 30%, transparent 70%), url("https://www.neuroblastoma.org.au/Handlers/GetImage.ashx?IDMF=db6b2994-65bc-4ede-8054-d722fdd0fc7f");
  }
  body.appeal-hazzys-christmas-wish .headerText{
    background-image: linear-gradient(to right, $brand-primary 30%, transparent 70%), url("https://www.neuroblastoma.org.au/Handlers/GetImage.ashx?IDMF=4d40a44e-c5cd-4a46-9146-7d1f3b7c103f");
  }

  body.appeal-donate-today, 
  body.appeal-donate-to-our-survive-and-thrive-campaign-this-eofy,
  body.appeal-hazzys-christmas-wish {    
    .carousel {
      display: none;
    }
    .headerText{
      background-size: cover;
      padding-top: 10px;
      padding-bottom: 10px;
        .title, .pageContent {
          color: white;
        }
    }
    .donationwidget {
      max-width: 420px;
      width: 100%;
    }
  }
}

// SF#66669919 - Homepage feeds layout
section.homeFeed.homeFeedBox2 {
  padding-top: 0;
}